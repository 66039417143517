import React from 'react'
import { Link } from 'gatsby'
import logo from '../img/filoz-logo.png'
import classNames from 'classnames'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: ''
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    var getNavbarItemClass = (navbarItem) => {
      var activeMenuItem = this.state.activeMenuItem;
      var navBarItemClass = classNames({
        'navbar-item': true,
        'is-active': navbarItem === activeMenuItem
      })
      return navBarItemClass
    }

    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item" title="Logo">
              <img src={logo} alt="Filoz"
              style={{
                width: '100',
                height: '44'
              }} />
            </Link>
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start has-text-centered">
              <Link
                className={getNavbarItemClass('Home')}
                activeClassName="is-active"
                onClick={() => {
                  this.toggleHamburger()
                  }}  to="/">
                Home
              </Link>
              <Link
                className={getNavbarItemClass('News')}
                activeClassName="is-active"
                onClick={() => {
                  this.toggleHamburger()
                  }} to="/news">
                News
              </Link>
              <Link
                className={getNavbarItemClass('Racing')}
                activeClassName="is-active"
                onClick={() => {
                  this.toggleHamburger()
                  }} to="/racing">
                Racing
              </Link>
              <Link
                className={getNavbarItemClass('Results')}
                activeClassName="is-active"
                onClick={() => {
                  this.toggleHamburger()
                  }} to="/results">
                Results
              </Link>
              <Link
                className={getNavbarItemClass('Membership')}
                activeClassName="is-active"
                onClick={() => {
                  this.toggleHamburger()
                  }} to="/membership">
                Membership
              </Link>
              <Link
                className={getNavbarItemClass('Sponsors')}
                activeClassName="is-active"
                onClick={() => {
                  this.toggleHamburger()
                  }} to="/sponsors">
                Sponsors
              </Link>
              <Link
                className={getNavbarItemClass('Contact')}
                activeClassName="is-active"
                onClick={() => {
                  this.toggleHamburger()
                  }} to="/contact">
                Contact
              </Link>
            </div>
            <div className="navbar-end has-text-centered">
              {/* place items here as required */}
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
